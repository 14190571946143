@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-background-color: white !default;
$header-text-color: $black !default;

.simple-header {
	$this: &;

	background: $header-background-color;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 14px;
	height: 60px;
	box-sizing: border-box;
	color: $header-text-color;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

	@include gridle_state(md) {
		box-shadow: none;
	}

	&__back {
		position: absolute;
		left: 14px;
		display: flex;
		align-items: center;
		z-index: 1;
		cursor: pointer;

		.back-button {
			.relative-link__text {
				color: $header-text-color; // fix arrow in EK may be invisible
			}
		}

		.back-button__label {
			display: none;

			@include gridle_state(md) {
				display: block;
				color: $header-text-color;
				margin-left: 5px;
			}
		}
	}

	&__phone {
		position: absolute;
		right: 14px;
		z-index: 1;

		&-button {
			color: $header-text-color;

			@include gridle_state(md) {
				display: none;
			}
		}
	}

	&__operator {
		position: fixed;
		top: 15px;
		right: 40px;
		color: $black;
		padding: 5px 10px;
		border: 1px solid $grey-medium;
		background: $grey-light;
		border-radius: $border-radius-small;
		z-index: 10;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: center;

		.logo-brand {
			width: 230px;
			max-height: 40px;
		}
	}

	&--eh {
		#{$this}__title {
			.logo-brand {
				width: 60px;
				height: 90px;
				transform: translateY(-12px);
				max-height: initial;
			}

			@include gridle_state(md) {
				justify-content: left;
				padding-left: 40px;
				max-width: 1280px;
				margin: 0 auto;
				width: 100%;

				.logo-brand {
					height: 167px;
					width: 100px;
					transform: translateY(16px);
				}
			}
		}
	}
}
