@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$right: 350px;
$hero-image-width: 615px; // pris dans src/app/pages/.brands/SS/Auth/auth-marketing.scss L7
$height: 575px; // pris dans src/app/pages/Auth/auth-layer.scss L54
$text-shadow-mobile: 0 2px 20px rgba(0, 0, 0, 0.3);
$auth-forgotten-password-margin-bottom: 25px;
$auth-form-text-color-static: $black !default;
$auth-form-background-static: white !default;

.auth-modal {
	position: relative;
	width: $right + $hero-image-width;
	border-radius: $border-radius-big;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: $height;
	padding: 15px 40px 0px 20px;

	.auth {
		min-width: 100%;
		width: 100%;

		&__accept-cgv,
		&__forgotten-password {
			height: 45px;
		}
	}

	&__auth-layer {
		width: 100%;
		position: relative;
		z-index: 1;

		.auth-layer {
			display: flex;

			&__photo {
				position: relative;
				flex: 1;
				text-align: left;
			}

			&__auth-form {
				display: flex;
				flex-direction: column;
				background: $auth-form-background-static;
				width: 350px;

				.auth__main {
					background: transparent;
					height: initial;
				}
			}
		}
	}

	&__hero-image {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 1;
	}

	&__certifications {
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		width: 100%;
		height: 50px;
		background-image: linear-gradient(to bottom, rgba(64, 64, 64, 0), rgba(28, 40, 53, 0.85));
		text-align: center;
		padding-bottom: 10px;
		z-index: 1;

		svg {
			fill: white;
			stroke: white;

			&:first-of-type {
				border-right: 1px solid white;
				padding-right: 10px;
				margin-right: 10px;
			}
		}
	}
}
