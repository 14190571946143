@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-background-color: white !default;
$smartdp-header-filter-box-shadow: unset !default;
$header-text-color: $black !default;
$header-text-transform: uppercase !default;
$menu-account-menu-item-color: $black !default;
$menu-background-color: white !default;
$menu-text-color: $black !default;

.smart-dp-header {
	$this: &;

	height: 60px;
	background: $header-background-color;
	padding: 0 15px;
	z-index: 3; // pour que le side panel soit au dessus du sdp summary
	box-shadow: $smartdp-header-filter-box-shadow;
  	position: relative;

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
		height: 100%; // center the back arrow
	}

	&__back {
		margin-right: 10px;
		display: flex;
		align-items: center;

		svg {
			color: $header-text-color;
		}
	}

	&__logo {
		@include gridle_state(md) {
			// pour éviter que le logo soit collé au bord de l'écran
			margin-left: 40px;
		}
	}

	&--eh {
		#{$this}__container {
			justify-content: flex-start;
			width: 100%;
		}

		#{$this}__logo {
			margin-left: 0px;
			height: 100%;
			background: $color-secondary;
			z-index: 2;

			.relative-link {
				display: block; // evite que le logo bouge quand on passe de la listing sdp a la fiche produit sdp
			}

			.logo-brand {
				width: 70px;
				height: 100px;
				transform: translateY(0);
			}

			&--small {
				text-align: center;

				.logo-brand {
					width: 60px;
					height: 90px;
					transform: translateY(-27px);
				}
			}

			@include gridle_state(md) {
				.logo-brand {
					width: 100px;
					height: 140px;
				}
			}
		}

		#{$this}__form {
			margin-left: 25px;
		}
	}

	&__operator {
		position: fixed;
		top: 15px;
		right: 40px;
		color: $black;
		padding: 5px 10px;
		border: 1px solid $grey-medium;
		background: $grey-light;
		border-radius: $border-radius-small;
		z-index: 10;
	}

	&__account-menu {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		box-sizing: border-box;
		margin-left: auto;

		@include gridle_state(md) {
			margin-left: auto;
			margin-right: 40px;
			align-self: flex-end;

			.relative-link {
				height: 100%;
				width: 100%;
				cursor: pointer;
				color: $header-text-color;
				text-transform: $header-text-transform;
				border-radius: 0;
				box-sizing: border-box;
			}
		}
	}

	&__dropdown {
		min-width: 270px;
		z-index: 3;
		padding-top: 9px;

		.signup-menu-account {
			width: 375px;
			border-radius: $border-radius-big;
			box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.3);
			border: $header-border-bottom;

			& .signup-menu-account__dynamic-message {
				border-radius: $border-radius-big $border-radius-big 0 0;
			}

			&__signup-intro {
				padding: 10px 20px;
				border-radius: $border-radius-big $border-radius-big 0 0;
				font-size: 1.2rem;
			}

			&__body {
				border-radius: 0 0 $border-radius-big $border-radius-big;
				background: $header-background-color;
			}

			&__nav-item {
				padding-left: 20px;
				padding-right: 20px;
				height: 60px;
				display: flex;
				align-items: center;
				border-top: 1px solid $grey-medium;

				& svg {
					margin-right: 20px;
				}

				.relative-link {
					justify-content: flex-start;
					font-size: 1.3rem;
					font-weight: 700;
					color: $menu-account-menu-item-color;
				}

				&--bold {
					.relative-link {
						@extend %font-bold;
					}
				}

				&--regular {
					.relative-link {
						color: $black;
					}
				}
			}
		}
	}

	&__hamburger {
		position: relative;

		.dot-notification {
			position: absolute;
			top: -10px;
			right: -10px;
		}

		@include gridle_state(md) {
			display: none;
		}
	}

	@include gridle_state(md) {
		height: 80px;
		width: 100%;
		padding-left: 0;
		padding-right: 0;

		&__container {
			max-width: 1280px;
			width: 100%;
			height: 100%;
			margin: 0 auto;
		}

		&__back {
			margin-right: 30px;
			display: flex;
			align-items: center;

			svg {
				width: 25px;
				height: 25px;
				color: $header-text-color;
			}
		}
	}

	.smartdp-menu-authenticated {
		background: $header-background-color;
		border: $header-border-bottom;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		border-radius: $border-radius-big;
		padding-top: 20px;
		width: 375px;
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.5);
	}

	&__form {
		width: 100%;
		max-width: 900px;
		.sdp-search-form {
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
			border-radius: $border-radius-big;
			overflow: hidden;

			&__footer {
				height: 45px;
				width: 65px;
				flex: none;
				& button {
					border-radius: unset;
					height: 45px;
					min-height: 45px;
				}
			}
		}
	}

	.tooltip-arrow {
		position: absolute;
		background: white;
		width: 8px;
		height: 8px;
		transform: rotate(45deg);
	}
}
