@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
@import "../.styles/reset";
@import "../.styles/settings";
@import "~themes/fonts";
@import "../.styles/animations";
@import "../.styles/base";
@import "../.styles/icons";
@import "../.styles/percy";
@import "~react-tabs/style/react-tabs.scss";
@import "~themes/push";

// Manuellement généré pour le fonctionnement de gridle.js.
// @see https://github.com/olivierbossel/gridle/issues/42
#gridle-settings {
	content: '{ "version" : "2.0.46", "states" : { "default":{ "name" : "default", "min-width" : null, "max-width" : null, "query" : null, "classes" : true, "context" : "24", "column-width" : null, "gutter-width" : "4px", "gutter-height" : "0", "gutter-top" : "0", "gutter-right" : "2px", "gutter-bottom" : "0", "gutter-left" : "2px", "direction" : "ltr", "dir-attribute" : false, "name-multiplicator" : "1", "states-classes" : false, "classes-prefix" : "", "_" : true }, "sm":{ "name" : "sm", "min-width" : "768px", "max-width" : null, "query" : "screen and (min-width: 768px)", "classes" : true, "context" : "24", "column-width" : null, "gutter-width" : "4px", "gutter-height" : "0", "gutter-top" : "0", "gutter-right" : "2px", "gutter-bottom" : "0", "gutter-left" : "2px", "direction" : "ltr", "dir-attribute" : false, "name-multiplicator" : "1", "states-classes" : false, "classes-prefix" : "", "_" : true }, "md":{ "name" : "md", "min-width" : "1024px", "max-width" : null, "query" : "screen and (min-width: 1024px)", "classes" : true, "context" : "24", "column-width" : null, "gutter-width" : "4px", "gutter-height" : "0", "gutter-top" : "0", "gutter-right" : "2px", "gutter-bottom" : "0", "gutter-left" : "2px", "direction" : "ltr", "dir-attribute" : false, "name-multiplicator" : "1", "states-classes" : false, "classes-prefix" : "", "_" : true }, "lg":{ "name" : "lg", "min-width" : "1441px", "max-width" : null, "query" : "screen and (min-width: 1441px)", "classes" : true, "context" : "24", "column-width" : null, "gutter-width" : "4px", "gutter-height" : "0", "gutter-top" : "0", "gutter-right" : "2px", "gutter-bottom" : "0", "gutter-left" : "2px", "direction" : "ltr", "dir-attribute" : false, "name-multiplicator" : "1", "states-classes" : false, "classes-prefix" : "", "_" : true }} }';
}
