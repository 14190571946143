@media only percy {
	.progressive-image,
	.background-progressive-image__image,
	.cookie-policy-footer {
		visibility: hidden;
	}

	.product-time__time,
	.loading-bar {
		display: none;
	}

	iframe {
		display: none;
	}
}
