@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.notification {
	background: $color-secondary;
	border-radius: 50%;
	font-size: 0.9rem;
	padding: 0 4px;
	position: relative;
	top: -5px;
	margin-left: 2px;
}

.badge-sponsorship-ek {
	height: 25px;
	width: 83px;
	background-image: url("/static/icons/icon-new.svg");
	margin-left: 12px;
}

.banniere-sponsorship-ek {
	margin-bottom: 15px;

	@include gridle_state(md) {
		margin-bottom: 25px;
	}

	&__ico {
		width: 100%;
		height: auto;
	}
}

.header__hamburger {
	.notification {
		top: -15px;
		margin-left: -5px;
	}
}
