@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.search-booking-form {
	&__title {
		text-align: center;
	}

	&__form {
		margin-top: 20px;
	}

	&__input {
		position: relative;
		margin-bottom: 14px;

		// suppression de la croix de reinit sur IE pour pas empieter sur les pictos
		input {
			&::-ms-clear {
				display: none;
			}

			color: white;
			background-color: rgba(0, 0, 0, 0.15);

			&:-webkit-autofill {
				-webkit-text-fill-color: white;
				-webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.3) inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}

		.control-group {
			&--touched {
				.control-group__label {
					color: white;
				}
			}

			&__label {
				color: white;
			}

			&--error .control-group__input {
				background: rgba(0, 0, 0, 0.15);
			}

			&__input {
				color: white;
				padding-right: 40px; // empecher la valeur de l'input de passer par dessus l'icone

				&:focus,
				&:hover {
					border: 1px solid white;
				}
			}
		}

		.icon {
			height: 20px;
			width: 20px;
		}
	}

	&__link {
		color: white;
		margin-bottom: 14px;
		text-align: right;

		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}

	&__info-picto {
		position: absolute;
		top: 1px;
		right: 0;
		padding: 13px;
	}

	&__button {
		position: relative;
		height: 45px;

		.button {
			height: 45px;
		}

		.popover {
			bottom: 40px;
		}
	}

	&__popover {
		z-index: 2;

		.Popover-tip {
			fill: white;
			stroke: $grey-light;
			stroke-width: 1px;
			margin-top: -1px;
		}
	}

	&__help {
		background-color: white;
		border: 1px solid $grey-light;
		border-radius: 3px;
		padding: 14px;
		box-sizing: border-box;
		width: 270px;
		box-shadow: -15px 23px 47px -26px rgba(0, 0, 0, 0.67);
	}
}
