@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.footer-logos {
	display: flex;
	justify-content: center;
	align-items: center;

	&__item {
		margin-right: 10px;
	}

	@include gridle_state(md) {
		justify-content: flex-start;
	}
}
