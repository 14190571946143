@font-face {
	font-family: "heroic-condensed-medium-oblique";
	src: url("/static/font/heroiccondensed/heroic-condensed-medium-oblique.woff2") format("woff2"),
		url("/static/font/heroiccondensed/heroic-condensed-medium-oblique.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "emirates-bold";
	src: url("/static/font/emirates/emirates-bold-v3.woff2") format("woff2"),
		url("/static/font/emirates/emirates-bold-v3.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
