@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-height: 70px !default;
$auth-header-background-color: white !default;
$header-background-color: white !default;
$auth-header-text-color: $black !default;
$header-text-color: $black !default;

.home-header {
	z-index: 2;
	border: none;
	background-color: $header-background-color;
	color: $header-text-color;
	width: 100vw;

	@include gridle_state(md) {
		height: $header-height;

		.smart-dp-header--eh .smart-dp-header__logo .logo-brand {
			height: 140px; // sinon forcé à 100px par HomeDesktop.scss
		}
	}
}
