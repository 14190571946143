@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.sdp-search-summary {
	display: flex;
	width: 100%;

	&__itinerary {
		@extend %font-bold;
		color: $black;
		font-size: 1.3rem;
		display: flex;
		align-items: center;

		.product-name-with-category {
			@extend %font-bold;
			color: $black;
			font-size: 1.3rem;
		}

		svg {
			width: 12px;
			height: 12px;
			margin-left: 5px;
			margin-right: 5px;
		}

		.relative-link {
			@extend %link;
			font-size: 1.3rem;
			text-decoration: underline;
		}
	}

	&__itinerary-flex {
		display: flex;
		align-items: center;
	}

	&__details {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		padding-right: 15px;
	}

	.travel-dates {
		display: flex;
		align-items: center;
		margin-top: 0;

		&__dates {
			font-size: 1.1rem;
			color: $black;
			margin-right: 5px;
		}

		&__duration {
			font-size: 1.1rem;
			color: $black;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&__occupancies {
		font-size: 1.1rem;
		color: $black;
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		flex-grow: 1;

		svg {
			stroke: black;
			width: 22px;
			height: 20px;
		}
	}

	&__magnifying-glass {
		width: 21px;
		height: 21px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-left: 1px solid $grey-medium;
		padding-left: 10px;
	}

	&__side-panel {
		background-color: $grey-light;
		padding: 0 10px;
		height: 100%;

		.sdp-search-form {
			width: calc(100% - 20px);
		}

		@include gridle_state(md) {
			background-color: unset;
			padding: 0;
		}
	}
}
