@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$menu-account-menu-item-color: $black !default;

.smart-dp-footer{

	&__flashsale,
	&__item{
	  @extend %font-bold;
	  display: flex;
	  align-items: center;
	  justify-content: flex-start;
	  color: $menu-account-menu-item-color !important;
	  padding-bottom: 18px;
	  padding-top: 20px;
	  padding-left: 25px;
	  border-bottom: 1px solid $grey-medium;

	  svg {
		height: 20px;
		width: 20px;
		margin-right: 18px;
	  }

	  &:first-of-type {
		border-top: 1px solid $grey-medium;
	  }

	  &:last-of-type {
		@include gridle_state(md) {
		  border-bottom: none;
		}
	  }
	}
}