@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/settings";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$auth-form-text-color-static: $black !default;
$auth-form-background-static: white !default;

.auth-layer {
	width: 100%; // eviter que la largeur ne change au switch sign login

	.auth {
		// pour supprimer le max-width: 307px du fichier auth pour ne pas casser les autres brands
		max-width: initial;

		&__forgotten-password {
			justify-content: flex-end;
			align-items: flex-start;

			.relative-link {
				font-size: 1rem;
				color: $auth-form-text-color-static;
			}
		}

		&__switch {
			&__item {
				color: $auth-form-text-color-static;
			}
		}

		&__headline {
			color: $auth-form-text-color-static;
		}

		&__separator {
			color: $auth-form-text-color-static;

			&::before,
			&::after {
				background: $auth-form-text-color-static;
			}
		}

		&__accept-cgv {
			color: $auth-form-text-color-static;

			.relative-link {
				color: $auth-form-text-color-static;
			}
		}
	}

	&__auth-form {
		background: $auth-form-background-static;
		border-radius: 5px;

		@include gridle_state(md) {
			background: none;
		}

		.control-group__label {
			color: $auth-form-text-color-static;
		}

		.control-group__input {
			color: $auth-form-text-color-static;
			background: $auth-form-background-static;
			border-radius: $border-radius-small;
			border-color: $auth-form-text-color-static;
		}

		.checkbox {
			path,
			g {
				stroke: $auth-form-text-color-static;
			}
		}

		.checkbox--checked {
			path,
			g {
				fill: $auth-form-text-color-static;
			}
		}

		.auth__action {
			margin-top: 25px;

			@include gridle_state(sm) {
				margin-top: 0;
			}
		}

		input {
			&:-webkit-autofill {
				-webkit-text-fill-color: $auth-form-text-color-static;
				-webkit-box-shadow: 0 0 0 1000px rgba($auth-form-background-static, 0.3) inset;
				transition: background-color 5000s ease-in-out 0s;
			}
		}

		&--darksite {
			border-radius: 20px;
			background: none;

			.search-booking-form,
			.recover-booking-data-form {
				width: 260px;
				border-radius: 20px;
				background-color: rgba(15, 44, 57, 0.45);
				padding: 30px;

				&__title {
					display: none;
				}

				@include gridle_state(md) {
					background: none;
				}
			}
		}
	}

	&__photo {
		text-align: center;
	}
}
